import { notification } from "antd";

export const DOMAIN = window.location.href.includes("localhost") ? "http://localhost:8000" : window.location.origin;

export enum CreateStepType {
  Form,
  Workflow,
}

export const getAccessToken = (): string => {
  return localStorage.getItem("access_token") ?? "";
};

export type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (type: NotificationType, successDescription?: string) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: successDescription,
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something went wrong.",
    });
  }
};

export const currencies = [
  {
    label: "KZT",
    value: "KZT",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "RMB",
    value: "RMB",
  },
  {
    label: "EURO",
    value: "EURO",
  },
  {
    label: "RUB",
    value: "RUB",
  },
];

export const paymentWorkflowSteps = ["Draft", "OnCoordination", "Coordinated", "PartialPaid", "Paid"];
export const blockPaymentStepEdit = ["Coordinated", "PartialPaid", "Paid"];
