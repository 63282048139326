/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveWorkflow } from '../models/ApproveWorkflow';
import type { Assignment } from '../models/Assignment';
import type { ChangeRequestOwner } from '../models/ChangeRequestOwner';
import type { Contract } from '../models/Contract';
import type { ContractCreate } from '../models/ContractCreate';
import type { ContractUpdate } from '../models/ContractUpdate';
import type { CostItem } from '../models/CostItem';
import type { Counterparty } from '../models/Counterparty';
import type { CreateAttachment } from '../models/CreateAttachment';
import type { CreateRequestComment } from '../models/CreateRequestComment';
import type { CreateRequestForm } from '../models/CreateRequestForm';
import type { CreateStaff } from '../models/CreateStaff';
import type { Email } from '../models/Email';
import type { ExtendRequest } from '../models/ExtendRequest';
import type { Feedback } from '../models/Feedback';
import type { FilterParameters } from '../models/FilterParameters';
import type { Form } from '../models/Form';
import type { GetAttachment } from '../models/GetAttachment';
import type { GetMyRequests } from '../models/GetMyRequests';
import type { MeasureUnit } from '../models/MeasureUnit';
import type { PaginatedAssignmentFullList } from '../models/PaginatedAssignmentFullList';
import type { PaginatedAssignmentList } from '../models/PaginatedAssignmentList';
import type { PaginatedContractListList } from '../models/PaginatedContractListList';
import type { PaginatedFeedbackList } from '../models/PaginatedFeedbackList';
import type { PaginatedFormList } from '../models/PaginatedFormList';
import type { PaginatedGetMyRequestsList } from '../models/PaginatedGetMyRequestsList';
import type { PaginatedPaymentHistoryActionList } from '../models/PaginatedPaymentHistoryActionList';
import type { PaginatedPaymentListList } from '../models/PaginatedPaymentListList';
import type { PaginatedPurchaseOrderHistoryActionList } from '../models/PaginatedPurchaseOrderHistoryActionList';
import type { PaginatedPurchaseOrderListList } from '../models/PaginatedPurchaseOrderListList';
import type { PaginatedPurchaseRequestListList } from '../models/PaginatedPurchaseRequestListList';
import type { PaginatedReceivingHistoryActionList } from '../models/PaginatedReceivingHistoryActionList';
import type { PaginatedReceivingListList } from '../models/PaginatedReceivingListList';
import type { PaginatedRequestApproverList } from '../models/PaginatedRequestApproverList';
import type { PaginatedRequestFormFieldValueList } from '../models/PaginatedRequestFormFieldValueList';
import type { PaginatedRequestList } from '../models/PaginatedRequestList';
import type { PaginatedRequestsByRequestNumberList } from '../models/PaginatedRequestsByRequestNumberList';
import type { PaginatedTemplateList } from '../models/PaginatedTemplateList';
import type { PaginatedTemplateTypeFilteredByGroupsList } from '../models/PaginatedTemplateTypeFilteredByGroupsList';
import type { PaginatedTimeRecordList } from '../models/PaginatedTimeRecordList';
import type { PaginatedTrainingList } from '../models/PaginatedTrainingList';
import type { PaginatedViewRequestApproverList } from '../models/PaginatedViewRequestApproverList';
import type { PasswordToken } from '../models/PasswordToken';
import type { PatchedAssignment } from '../models/PatchedAssignment';
import type { PatchedCostItem } from '../models/PatchedCostItem';
import type { PatchedFeedback } from '../models/PatchedFeedback';
import type { PatchedForm } from '../models/PatchedForm';
import type { PatchedGetAttachment } from '../models/PatchedGetAttachment';
import type { PatchedMeasureUnit } from '../models/PatchedMeasureUnit';
import type { PatchedProduct } from '../models/PatchedProduct';
import type { PatchedProject } from '../models/PatchedProject';
import type { PatchedPurchaseRequestProject } from '../models/PatchedPurchaseRequestProject';
import type { PatchedRequest } from '../models/PatchedRequest';
import type { PatchedRequestApprover } from '../models/PatchedRequestApprover';
import type { PatchedRequestFormFieldValue } from '../models/PatchedRequestFormFieldValue';
import type { PatchedTemplate } from '../models/PatchedTemplate';
import type { PatchedTemplateType } from '../models/PatchedTemplateType';
import type { PatchedTimeRecord } from '../models/PatchedTimeRecord';
import type { PatchedTraining } from '../models/PatchedTraining';
import type { PatchedUser } from '../models/PatchedUser';
import type { PatchedWarehouse } from '../models/PatchedWarehouse';
import type { PaymentCreate } from '../models/PaymentCreate';
import type { PaymentRetrieve } from '../models/PaymentRetrieve';
import type { PerformAction } from '../models/PerformAction';
import type { Product } from '../models/Product';
import type { Project } from '../models/Project';
import type { PurchaseOrderCreate } from '../models/PurchaseOrderCreate';
import type { PurchaseOrderCreateResponse } from '../models/PurchaseOrderCreateResponse';
import type { PurchaseOrderList } from '../models/PurchaseOrderList';
import type { PurchaseOrderRetrieve } from '../models/PurchaseOrderRetrieve';
import type { PurchaseRequestCreate } from '../models/PurchaseRequestCreate';
import type { PurchaseRequestHistoryAction } from '../models/PurchaseRequestHistoryAction';
import type { PurchaseRequestList } from '../models/PurchaseRequestList';
import type { PurchaseRequestProject } from '../models/PurchaseRequestProject';
import type { PurchaseRequestRetrieve } from '../models/PurchaseRequestRetrieve';
import type { PurchaseRequestUpdate } from '../models/PurchaseRequestUpdate';
import type { ReceivingCreate } from '../models/ReceivingCreate';
import type { ReceivingRetrieve } from '../models/ReceivingRetrieve';
import type { Request } from '../models/Request';
import type { RequestApprover } from '../models/RequestApprover';
import type { RequestComment } from '../models/RequestComment';
import type { RequestFormFieldValue } from '../models/RequestFormFieldValue';
import type { ResetToken } from '../models/ResetToken';
import type { StartWorkflow } from '../models/StartWorkflow';
import type { Template } from '../models/Template';
import type { TemplateType } from '../models/TemplateType';
import type { TimeRecord } from '../models/TimeRecord';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { Training } from '../models/Training';
import type { UpdatePaymentRecord } from '../models/UpdatePaymentRecord';
import type { User } from '../models/User';
import type { UserList } from '../models/UserList';
import type { UserLogin } from '../models/UserLogin';
import type { Warehouse } from '../models/Warehouse';
import type { WorkCalendarList } from '../models/WorkCalendarList';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @param requestBody
     * @returns UserLogin
     * @throws ApiError
     */
    public static apiAccountsLoginCreate(
        requestBody: UserLogin,
    ): CancelablePromise<UserLogin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/accounts/login/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @param requestBody
     * @returns TokenRefresh
     * @throws ApiError
     */
    public static apiAccountsRefreshCreate(
        requestBody: TokenRefresh,
    ): CancelablePromise<TokenRefresh> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/accounts/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiAccountsRegisterCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/accounts/register/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiAccountsResetPasswordCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/accounts/reset-password/',
        });
    }
    /**
     * @param search
     * @returns UserList
     * @throws ApiError
     */
    public static apiAccountsUsersList(
        search?: string,
    ): CancelablePromise<Array<UserList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/accounts/users/',
            query: {
                'search': search,
            },
        });
    }
    /**
     * @param formData
     * @returns User
     * @throws ApiError
     */
    public static apiAccountsUsersCreate(
        formData: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/accounts/users/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A UUID string identifying this Аккаунт.
     * @returns User
     * @throws ApiError
     */
    public static apiAccountsUsersRetrieve(
        id: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/accounts/users/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A UUID string identifying this Аккаунт.
     * @param formData
     * @returns User
     * @throws ApiError
     */
    public static apiAccountsUsersUpdate(
        id: string,
        formData: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/accounts/users/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A UUID string identifying this Аккаунт.
     * @param formData
     * @returns User
     * @throws ApiError
     */
    public static apiAccountsUsersPartialUpdate(
        id: string,
        formData?: PatchedUser,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/accounts/users/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A UUID string identifying this Аккаунт.
     * @returns void
     * @throws ApiError
     */
    public static apiAccountsUsersDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/accounts/users/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns GetAttachment
     * @throws ApiError
     */
    public static apiAttachmentsList(): CancelablePromise<Array<GetAttachment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/attachments/',
        });
    }
    /**
     * @param formData
     * @returns CreateAttachment
     * @throws ApiError
     */
    public static apiAttachmentsCreate(
        formData: CreateAttachment,
    ): CancelablePromise<CreateAttachment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/attachments/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @returns GetAttachment
     * @throws ApiError
     */
    public static apiAttachmentsRetrieve(
        id: number,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @param formData
     * @returns GetAttachment
     * @throws ApiError
     */
    public static apiAttachmentsUpdate(
        id: number,
        formData?: GetAttachment,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @param formData
     * @returns GetAttachment
     * @throws ApiError
     */
    public static apiAttachmentsPartialUpdate(
        id: number,
        formData?: PatchedGetAttachment,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @returns void
     * @throws ApiError
     */
    public static apiAttachmentsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @returns GetAttachment
     * @throws ApiError
     */
    public static apiAttachmentsDownloadRetrieve(
        id: number,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/attachments/{id}/download/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param contractNumber
     * @param counterparty
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param startDate
     * @param subject
     * @returns PaginatedContractListList
     * @throws ApiError
     */
    public static apiContractsContractsList(
        contractNumber?: string,
        counterparty?: number,
        number?: string,
        page?: number,
        pageSize?: number,
        startDate?: string,
        subject?: string,
    ): CancelablePromise<PaginatedContractListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/contracts/',
            query: {
                'contract_number': contractNumber,
                'counterparty': counterparty,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'start_date': startDate,
                'subject': subject,
            },
        });
    }
    /**
     * @param formData
     * @returns ContractCreate
     * @throws ApiError
     */
    public static apiContractsContractsCreate(
        formData: ContractCreate,
    ): CancelablePromise<ContractCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/contracts/contracts/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @returns Contract
     * @throws ApiError
     */
    public static apiContractsContractsRetrieve(
        id: number,
    ): CancelablePromise<Contract> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @param formData
     * @returns ContractUpdate
     * @throws ApiError
     */
    public static apiContractsContractsUpdate(
        id: number,
        formData: ContractUpdate,
    ): CancelablePromise<ContractUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @returns any No response body
     * @throws ApiError
     */
    public static apiContractsContractsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @returns void
     * @throws ApiError
     */
    public static apiContractsContractsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static apiContractsContractsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/contracts/contracts/generate_all_reports/',
        });
    }
    /**
     * @param counterparty
     * @returns any No response body
     * @throws ApiError
     */
    public static apiContractsContractsSearchByCounterpartyRetrieve(
        counterparty: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/contracts/search_by_counterparty/',
            query: {
                'counterparty': counterparty,
            },
        });
    }
    /**
     * @param q
     * @returns Counterparty
     * @throws ApiError
     */
    public static apiContractsCounterpartiesList(
        q?: string,
    ): CancelablePromise<Array<Counterparty>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/counterparties/',
            query: {
                'q': q,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param userId
     * @returns PaginatedAssignmentList
     * @throws ApiError
     */
    public static apiMainAssignmentsList(
        page?: number,
        pageSize?: number,
        userId?: string,
    ): CancelablePromise<PaginatedAssignmentList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/assignments/',
            query: {
                'page': page,
                'page_size': pageSize,
                'user_id': userId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Assignment
     * @throws ApiError
     */
    public static apiMainAssignmentsCreate(
        requestBody: Assignment,
    ): CancelablePromise<Assignment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/assignments/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this assignment.
     * @returns Assignment
     * @throws ApiError
     */
    public static apiMainAssignmentsRetrieve(
        id: number,
    ): CancelablePromise<Assignment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/assignments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this assignment.
     * @param requestBody
     * @returns Assignment
     * @throws ApiError
     */
    public static apiMainAssignmentsUpdate(
        id: number,
        requestBody: Assignment,
    ): CancelablePromise<Assignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/assignments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this assignment.
     * @param requestBody
     * @returns Assignment
     * @throws ApiError
     */
    public static apiMainAssignmentsPartialUpdate(
        id: number,
        requestBody?: PatchedAssignment,
    ): CancelablePromise<Assignment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/assignments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this assignment.
     * @returns void
     * @throws ApiError
     */
    public static apiMainAssignmentsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/assignments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ChangeRequestOwner
     * @throws ApiError
     */
    public static apiMainChangeRequestOwnerCreate(
        requestBody: ChangeRequestOwner,
    ): CancelablePromise<ChangeRequestOwner> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/change-request-owner/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CreateRequestComment
     * @throws ApiError
     */
    public static apiMainCreateRequestCommentCreate(
        requestBody: CreateRequestComment,
    ): CancelablePromise<CreateRequestComment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/create-request-comment/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param month
     * @param projectId
     * @param year
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainCurrentGroupRequestRetrieve(
        month: number,
        projectId: number,
        year: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/current-group-request/{year}/{month}/{project_id}/',
            path: {
                'month': month,
                'project_id': projectId,
                'year': year,
            },
        });
    }
    /**
     * @param month
     * @param userId
     * @param year
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainCurrentRequestRetrieve(
        month: number,
        userId: string,
        year: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/current-request/{year}/{month}/{user_id}/',
            path: {
                'month': month,
                'user_id': userId,
                'year': year,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainDownloadDocumentRetrieve(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/download-document/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainExportTimesheetRetrieve(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/export-timesheet/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ExtendRequest
     * @throws ApiError
     */
    public static apiMainExtendRequestCreate(
        requestBody: ExtendRequest,
    ): CancelablePromise<ExtendRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/extend-request/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param userId
     * @returns PaginatedFeedbackList
     * @throws ApiError
     */
    public static apiMainFeedbacksList(
        page?: number,
        pageSize?: number,
        userId?: string,
    ): CancelablePromise<PaginatedFeedbackList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/feedbacks/',
            query: {
                'page': page,
                'page_size': pageSize,
                'user_id': userId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Feedback
     * @throws ApiError
     */
    public static apiMainFeedbacksCreate(
        requestBody: Feedback,
    ): CancelablePromise<Feedback> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/feedbacks/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this feedback.
     * @returns Feedback
     * @throws ApiError
     */
    public static apiMainFeedbacksRetrieve(
        id: number,
    ): CancelablePromise<Feedback> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/feedbacks/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this feedback.
     * @param requestBody
     * @returns Feedback
     * @throws ApiError
     */
    public static apiMainFeedbacksUpdate(
        id: number,
        requestBody: Feedback,
    ): CancelablePromise<Feedback> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/feedbacks/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this feedback.
     * @param requestBody
     * @returns Feedback
     * @throws ApiError
     */
    public static apiMainFeedbacksPartialUpdate(
        id: number,
        requestBody?: PatchedFeedback,
    ): CancelablePromise<Feedback> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/feedbacks/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this feedback.
     * @returns void
     * @throws ApiError
     */
    public static apiMainFeedbacksDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/feedbacks/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedRequestFormFieldValueList
     * @throws ApiError
     */
    public static apiMainFormFieldValuesList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedRequestFormFieldValueList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/form-field-values/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns RequestFormFieldValue
     * @throws ApiError
     */
    public static apiMainFormFieldValuesCreate(
        requestBody: RequestFormFieldValue,
    ): CancelablePromise<RequestFormFieldValue> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/form-field-values/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request form field value.
     * @returns RequestFormFieldValue
     * @throws ApiError
     */
    public static apiMainFormFieldValuesRetrieve(
        id: number,
    ): CancelablePromise<RequestFormFieldValue> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/form-field-values/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this request form field value.
     * @param requestBody
     * @returns RequestFormFieldValue
     * @throws ApiError
     */
    public static apiMainFormFieldValuesUpdate(
        id: number,
        requestBody: RequestFormFieldValue,
    ): CancelablePromise<RequestFormFieldValue> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/form-field-values/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request form field value.
     * @param requestBody
     * @returns RequestFormFieldValue
     * @throws ApiError
     */
    public static apiMainFormFieldValuesPartialUpdate(
        id: number,
        requestBody?: PatchedRequestFormFieldValue,
    ): CancelablePromise<RequestFormFieldValue> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/form-field-values/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request form field value.
     * @returns void
     * @throws ApiError
     */
    public static apiMainFormFieldValuesDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/form-field-values/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedFormList
     * @throws ApiError
     */
    public static apiMainFormsList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedFormList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/forms/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Form
     * @throws ApiError
     */
    public static apiMainFormsCreate(
        requestBody: Form,
    ): CancelablePromise<Form> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/forms/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this form.
     * @returns Form
     * @throws ApiError
     */
    public static apiMainFormsRetrieve(
        id: number,
    ): CancelablePromise<Form> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/forms/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this form.
     * @param requestBody
     * @returns Form
     * @throws ApiError
     */
    public static apiMainFormsUpdate(
        id: number,
        requestBody: Form,
    ): CancelablePromise<Form> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/forms/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this form.
     * @param requestBody
     * @returns Form
     * @throws ApiError
     */
    public static apiMainFormsPartialUpdate(
        id: number,
        requestBody?: PatchedForm,
    ): CancelablePromise<Form> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/forms/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this form.
     * @returns void
     * @throws ApiError
     */
    public static apiMainFormsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/forms/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainGenerateDocumentRetrieve(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/generate-document/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainGetRequestCmsRetrieve(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/get-request-cms/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainGetRequestDocRetrieve(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/get-request-doc/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param search
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedRequestsByRequestNumberList
     * @throws ApiError
     */
    public static apiMainGetRequestsByNumberList(
        search: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedRequestsByRequestNumberList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/get-requests-by-number/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainInboxEmailsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/inbox-emails/',
        });
    }
    /**
     * @returns GetMyRequests
     * @throws ApiError
     */
    public static apiMainLast10RequestsList(): CancelablePromise<Array<GetMyRequests>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/last-10-requests/',
        });
    }
    /**
     * @param requestId
     * @returns RequestComment
     * @throws ApiError
     */
    public static apiMainListRequestCommentsList(
        requestId: number,
    ): CancelablePromise<Array<RequestComment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/list-request-comments/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedGetMyRequestsList
     * @throws ApiError
     */
    public static apiMainMyRequestsList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedGetMyRequestsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/my-requests/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns PerformAction
     * @throws ApiError
     */
    public static apiMainPerformActionCreate(
        requestBody: PerformAction,
    ): CancelablePromise<PerformAction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/perform-action/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param pmId
     * @returns Project
     * @throws ApiError
     */
    public static apiMainProjectsList(
        pmId?: string,
    ): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/projects/',
            query: {
                'pm_id': pmId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiMainProjectsCreate(
        requestBody: Project,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/projects/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this project.
     * @returns Project
     * @throws ApiError
     */
    public static apiMainProjectsRetrieve(
        id: number,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this project.
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiMainProjectsUpdate(
        id: number,
        requestBody: Project,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this project.
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static apiMainProjectsPartialUpdate(
        id: number,
        requestBody?: PatchedProject,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this project.
     * @returns void
     * @throws ApiError
     */
    public static apiMainProjectsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param projectId
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedAssignmentFullList
     * @throws ApiError
     */
    public static apiMainProjectsAssignmentsList(
        projectId: number,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedAssignmentFullList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/projects/{project_id}/assignments/',
            path: {
                'project_id': projectId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedRequestApproverList
     * @throws ApiError
     */
    public static apiMainRequestApproversList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedRequestApproverList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request-approvers/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns RequestApprover
     * @throws ApiError
     */
    public static apiMainRequestApproversCreate(
        requestBody: RequestApprover,
    ): CancelablePromise<RequestApprover> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/request-approvers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request approver.
     * @returns RequestApprover
     * @throws ApiError
     */
    public static apiMainRequestApproversRetrieve(
        id: number,
    ): CancelablePromise<RequestApprover> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request-approvers/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this request approver.
     * @param requestBody
     * @returns RequestApprover
     * @throws ApiError
     */
    public static apiMainRequestApproversUpdate(
        id: number,
        requestBody: RequestApprover,
    ): CancelablePromise<RequestApprover> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/request-approvers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request approver.
     * @param requestBody
     * @returns RequestApprover
     * @throws ApiError
     */
    public static apiMainRequestApproversPartialUpdate(
        id: number,
        requestBody?: PatchedRequestApprover,
    ): CancelablePromise<RequestApprover> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/request-approvers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request approver.
     * @returns void
     * @throws ApiError
     */
    public static apiMainRequestApproversDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/request-approvers/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestId
     * @param workflowId
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedViewRequestApproverList
     * @throws ApiError
     */
    public static apiMainRequestApproversGetApproversList(
        requestId: string,
        workflowId: number,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedViewRequestApproverList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request-approvers/get_approvers/',
            query: {
                'page': page,
                'page_size': pageSize,
                'request_id': requestId,
                'workflow_id': workflowId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateRequestForm
     * @throws ApiError
     */
    public static apiMainRequestFormCreate(
        requestBody: CreateRequestForm,
    ): CancelablePromise<CreateRequestForm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/request-form/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param formId
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainRequestFormRetrieve(
        formId: number,
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request-form/{request_id}/{form_id}/',
            path: {
                'form_id': formId,
                'request_id': requestId,
            },
        });
    }
    /**
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainRequestHistoryRetrieve(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request-history/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainRequestStatesRequestRetrieve(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request-states/request/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ApproveWorkflow
     * @throws ApiError
     */
    public static apiMainRequestWorkflowApproveCreate(
        requestBody: ApproveWorkflow,
    ): CancelablePromise<ApproveWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/request-workflow/approve/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ApproveWorkflow
     * @throws ApiError
     */
    public static apiMainRequestWorkflowRejectCreate(
        requestBody: ApproveWorkflow,
    ): CancelablePromise<ApproveWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/request-workflow/reject/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns StartWorkflow
     * @throws ApiError
     */
    public static apiMainRequestWorkflowStartCreate(
        requestBody: StartWorkflow,
    ): CancelablePromise<StartWorkflow> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/request-workflow/start/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param projectId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainRequestProjectCreate(
        projectId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/request/project/{project_id}/',
            path: {
                'project_id': projectId,
            },
        });
    }
    /**
     * @param shortName
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainRequestStatusesByShortNameRetrieve(
        shortName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request_statuses_by_short_name/{short_name}/',
            path: {
                'short_name': shortName,
            },
        });
    }
    /**
     * @param templateId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainRequestStatusesByTemplateRetrieve(
        templateId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/request_statuses_by_template/{template_id}/',
            path: {
                'template_id': templateId,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedRequestList
     * @throws ApiError
     */
    public static apiMainRequestsList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedRequestList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/requests/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Request
     * @throws ApiError
     */
    public static apiMainRequestsCreate(
        requestBody: Request,
    ): CancelablePromise<Request> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/requests/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns FilterParameters
     * @throws ApiError
     */
    public static apiMainRequestsByTemplateCreate(
        requestBody: FilterParameters,
    ): CancelablePromise<FilterParameters> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/requests-by-template/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param userId
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedGetMyRequestsList
     * @throws ApiError
     */
    public static apiMainRequestsByUserList(
        userId: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedGetMyRequestsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/requests-by-user/{user_id}/',
            path: {
                'user_id': userId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this request.
     * @returns Request
     * @throws ApiError
     */
    public static apiMainRequestsRetrieve(
        id: number,
    ): CancelablePromise<Request> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this request.
     * @param requestBody
     * @returns Request
     * @throws ApiError
     */
    public static apiMainRequestsUpdate(
        id: number,
        requestBody: Request,
    ): CancelablePromise<Request> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/requests/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request.
     * @param requestBody
     * @returns Request
     * @throws ApiError
     */
    public static apiMainRequestsPartialUpdate(
        id: number,
        requestBody?: PatchedRequest,
    ): CancelablePromise<Request> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/requests/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this request.
     * @returns void
     * @throws ApiError
     */
    public static apiMainRequestsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param userId
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedRequestList
     * @throws ApiError
     */
    public static apiMainRequestsGetRequestsForApprovalList(
        userId: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedRequestList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/requests/get_requests_for_approval/',
            query: {
                'page': page,
                'page_size': pageSize,
                'user_id': userId,
            },
        });
    }
    /**
     * @param shortName
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainSearchFieldsByTemplateRetrieve(
        shortName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/search-fields-by-template/{short_name}/',
            path: {
                'short_name': shortName,
            },
        });
    }
    /**
     * @returns User
     * @throws ApiError
     */
    public static apiMainStaffList(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/staff/',
        });
    }
    /**
     * @param formData
     * @returns CreateStaff
     * @throws ApiError
     */
    public static apiMainStaffCreate(
        formData: CreateStaff,
    ): CancelablePromise<CreateStaff> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/staff/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainStatusRequestCountRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/status-request-count/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainTemplateRequestCountRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/template-request-count/',
        });
    }
    /**
     * @param type
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedTemplateTypeFilteredByGroupsList
     * @throws ApiError
     */
    public static apiMainTemplateTypesList(
        type: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedTemplateTypeFilteredByGroupsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/template-types/',
            query: {
                'page': page,
                'page_size': pageSize,
                'type': type,
            },
        });
    }
    /**
     * @param requestBody
     * @returns TemplateType
     * @throws ApiError
     */
    public static apiMainTemplateTypesCreate(
        requestBody: TemplateType,
    ): CancelablePromise<TemplateType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/template-types/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this template type.
     * @returns TemplateType
     * @throws ApiError
     */
    public static apiMainTemplateTypesRetrieve(
        id: number,
    ): CancelablePromise<TemplateType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/template-types/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this template type.
     * @param requestBody
     * @returns TemplateType
     * @throws ApiError
     */
    public static apiMainTemplateTypesUpdate(
        id: number,
        requestBody: TemplateType,
    ): CancelablePromise<TemplateType> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/template-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this template type.
     * @param requestBody
     * @returns TemplateType
     * @throws ApiError
     */
    public static apiMainTemplateTypesPartialUpdate(
        id: number,
        requestBody?: PatchedTemplateType,
    ): CancelablePromise<TemplateType> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/template-types/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this template type.
     * @returns void
     * @throws ApiError
     */
    public static apiMainTemplateTypesDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/template-types/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedTemplateList
     * @throws ApiError
     */
    public static apiMainTemplatesList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedTemplateList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/templates/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Template
     * @throws ApiError
     */
    public static apiMainTemplatesCreate(
        requestBody: Template,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/templates/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param shortName
     * @returns Template
     * @throws ApiError
     */
    public static apiMainTemplatesRetrieve(
        shortName: string,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/templates/{short_name}/',
            path: {
                'short_name': shortName,
            },
        });
    }
    /**
     * @param shortName
     * @param requestBody
     * @returns Template
     * @throws ApiError
     */
    public static apiMainTemplatesUpdate(
        shortName: string,
        requestBody: Template,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/templates/{short_name}/',
            path: {
                'short_name': shortName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param shortName
     * @param requestBody
     * @returns Template
     * @throws ApiError
     */
    public static apiMainTemplatesPartialUpdate(
        shortName: string,
        requestBody?: PatchedTemplate,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/templates/{short_name}/',
            path: {
                'short_name': shortName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param shortName
     * @returns void
     * @throws ApiError
     */
    public static apiMainTemplatesDestroy(
        shortName: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/templates/{short_name}/',
            path: {
                'short_name': shortName,
            },
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedTimeRecordList
     * @throws ApiError
     */
    public static apiMainTimeRecordList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedTimeRecordList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/time-record/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param requestBody
     * @returns TimeRecord
     * @throws ApiError
     */
    public static apiMainTimeRecordCreate(
        requestBody: TimeRecord,
    ): CancelablePromise<TimeRecord> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/time-record/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this time record.
     * @returns TimeRecord
     * @throws ApiError
     */
    public static apiMainTimeRecordRetrieve(
        id: number,
    ): CancelablePromise<TimeRecord> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/time-record/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this time record.
     * @param requestBody
     * @returns TimeRecord
     * @throws ApiError
     */
    public static apiMainTimeRecordUpdate(
        id: number,
        requestBody: TimeRecord,
    ): CancelablePromise<TimeRecord> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/time-record/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this time record.
     * @param requestBody
     * @returns TimeRecord
     * @throws ApiError
     */
    public static apiMainTimeRecordPartialUpdate(
        id: number,
        requestBody?: PatchedTimeRecord,
    ): CancelablePromise<TimeRecord> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/time-record/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this time record.
     * @returns void
     * @throws ApiError
     */
    public static apiMainTimeRecordDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/time-record/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param date
     * @param userId
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedTimeRecordList
     * @throws ApiError
     */
    public static apiMainTimeRecordByDateList(
        date: string,
        userId: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedTimeRecordList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/time-record/by-date/{date}/{user_id}/',
            path: {
                'date': date,
                'user_id': userId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param month
     * @param userId
     * @param year
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedTimeRecordList
     * @throws ApiError
     */
    public static apiMainTimeRecordByMonthList(
        month: number,
        userId: string,
        year: number,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedTimeRecordList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/time-record/by-month/{year}/{month}/{user_id}/',
            path: {
                'month': month,
                'user_id': userId,
                'year': year,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param endDate
     * @param requestId
     * @param startDate
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainTimeRecordFetchRetrieve(
        endDate: string,
        requestId: number,
        startDate: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/time-record/fetch/{request_id}/{start_date}/{end_date}',
            path: {
                'end_date': endDate,
                'request_id': requestId,
                'start_date': startDate,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainTotalCountsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/total-counts/',
        });
    }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param userId
     * @returns PaginatedTrainingList
     * @throws ApiError
     */
    public static apiMainTrainingsList(
        page?: number,
        pageSize?: number,
        userId?: string,
    ): CancelablePromise<PaginatedTrainingList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/trainings/',
            query: {
                'page': page,
                'page_size': pageSize,
                'user_id': userId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Training
     * @throws ApiError
     */
    public static apiMainTrainingsCreate(
        requestBody: Training,
    ): CancelablePromise<Training> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/trainings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this training.
     * @returns Training
     * @throws ApiError
     */
    public static apiMainTrainingsRetrieve(
        id: number,
    ): CancelablePromise<Training> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/trainings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this training.
     * @param requestBody
     * @returns Training
     * @throws ApiError
     */
    public static apiMainTrainingsUpdate(
        id: number,
        requestBody: Training,
    ): CancelablePromise<Training> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/trainings/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this training.
     * @param requestBody
     * @returns Training
     * @throws ApiError
     */
    public static apiMainTrainingsPartialUpdate(
        id: number,
        requestBody?: PatchedTraining,
    ): CancelablePromise<Training> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/trainings/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this training.
     * @returns void
     * @throws ApiError
     */
    public static apiMainTrainingsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/trainings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainWithdrawRequestCreate(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/withdraw-request/{request_id}/',
            path: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param month
     * @param year
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMainWorkCalendarByMonthRetrieve(
        month: number,
        year: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/work-calendar/by-month/{year}/{month}/',
            path: {
                'month': month,
                'year': year,
            },
        });
    }
    /**
     * @param date
     * @returns WorkCalendarList
     * @throws ApiError
     */
    public static apiMainWorkCalendarsRetrieve(
        date: string,
    ): CancelablePromise<WorkCalendarList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/work-calendars/',
            query: {
                'date': date,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMyRequestsList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my-requests/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMyTasksList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my-tasks/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiMyTasksGetCountRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my-tasks/get_count/',
        });
    }
    /**
     * An Api View which provides a method to request a password reset token based on an e-mail address
     *
     * Sends a signal reset_password_token_created when a reset token was created
     * @param requestBody
     * @returns Email
     * @throws ApiError
     */
    public static apiPasswordResetCreate(
        requestBody: Email,
    ): CancelablePromise<Email> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * An Api View which provides a method to reset a password based on a unique token
     * @param requestBody
     * @returns PasswordToken
     * @throws ApiError
     */
    public static apiPasswordResetConfirmCreate(
        requestBody: PasswordToken,
    ): CancelablePromise<PasswordToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_reset/confirm/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * An Api View which provides a method to verify that a token is valid
     * @param requestBody
     * @returns ResetToken
     * @throws ApiError
     */
    public static apiPasswordResetValidateTokenCreate(
        requestBody: ResetToken,
    ): CancelablePromise<ResetToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_reset/validate_token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param counterparty
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param purchaseOrderNumber
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `Coordinated` - Coordinated
     * * `PartialPaid` - PartialPaid
     * * `Paid` - Paid
     * @returns PaginatedPaymentListList
     * @throws ApiError
     */
    public static apiPaymentsList(
        counterparty?: string,
        number?: string,
        page?: number,
        pageSize?: number,
        purchaseOrderNumber?: string,
        workflowStep?: 'Coordinated' | 'Draft' | 'OnCoordination' | 'Paid' | 'PartialPaid',
    ): CancelablePromise<PaginatedPaymentListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/',
            query: {
                'counterparty': counterparty,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'purchase_order_number': purchaseOrderNumber,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param requestBody
     * @returns PaymentCreate
     * @throws ApiError
     */
    public static apiPaymentsCreate(
        requestBody: PaymentCreate,
    ): CancelablePromise<PaymentCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @returns PaymentRetrieve
     * @throws ApiError
     */
    public static apiPaymentsRetrieve(
        id: number,
    ): CancelablePromise<PaymentRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @param requestBody
     * @returns PaymentCreate
     * @throws ApiError
     */
    public static apiPaymentsUpdate(
        id: number,
        requestBody: PaymentCreate,
    ): CancelablePromise<PaymentCreate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPaymentsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @returns void
     * @throws ApiError
     */
    public static apiPaymentsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param paymentId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPaymentsCoordinatePaymentCreate(
        paymentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/coordinate_payment/',
            query: {
                'payment_id': paymentId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static apiPaymentsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/generate_all_reports/',
        });
    }
    /**
     * @param paymentId
     * @param counterparty
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param purchaseOrderNumber
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `Coordinated` - Coordinated
     * * `PartialPaid` - PartialPaid
     * * `Paid` - Paid
     * @returns PaginatedPaymentHistoryActionList
     * @throws ApiError
     */
    public static apiPaymentsGetHistoryActionsList(
        paymentId: string,
        counterparty?: string,
        number?: string,
        page?: number,
        pageSize?: number,
        purchaseOrderNumber?: string,
        workflowStep?: 'Coordinated' | 'Draft' | 'OnCoordination' | 'Paid' | 'PartialPaid',
    ): CancelablePromise<PaginatedPaymentHistoryActionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/get_history_actions/',
            query: {
                'counterparty': counterparty,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'payment_id': paymentId,
                'purchase_order_number': purchaseOrderNumber,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param paymentId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPaymentsPublishPaymentCreate(
        paymentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/publish_payment/',
            query: {
                'payment_id': paymentId,
            },
        });
    }
    /**
     * @param paymentId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPaymentsReworkPaymentCreate(
        paymentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/rework_payment/',
            query: {
                'payment_id': paymentId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPaymentsUpdatePaymentRecordsCreate(
        requestBody: UpdatePaymentRecord,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/update_payment_records/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param createdBy
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param prName
     * @param project
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `OnApproval` - On Approval
     * * `Approved` - Approved
     * @returns PaginatedPurchaseRequestListList
     * @throws ApiError
     */
    public static apiPurchaseRequestsList(
        createdBy?: string,
        number?: string,
        page?: number,
        pageSize?: number,
        prName?: string,
        project?: number,
        workflowStep?: 'Approved' | 'Draft' | 'OnApproval' | 'OnCoordination',
    ): CancelablePromise<PaginatedPurchaseRequestListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/',
            query: {
                'created_by': createdBy,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'pr_name': prName,
                'project': project,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param formData
     * @returns PurchaseRequestCreate
     * @throws ApiError
     */
    public static apiPurchaseRequestsCreate(
        formData: PurchaseRequestCreate,
    ): CancelablePromise<PurchaseRequestCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @returns PurchaseRequestRetrieve
     * @throws ApiError
     */
    public static apiPurchaseRequestsRetrieve(
        id: number,
    ): CancelablePromise<PurchaseRequestRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @param formData
     * @returns PurchaseRequestUpdate
     * @throws ApiError
     */
    public static apiPurchaseRequestsUpdate(
        id: number,
        formData: PurchaseRequestUpdate,
    ): CancelablePromise<PurchaseRequestUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseRequestsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @returns void
     * @throws ApiError
     */
    public static apiPurchaseRequestsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param personId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseRequestsApproveRequestCreate(
        personId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/approve_request/',
            query: {
                'person_id': personId,
            },
        });
    }
    /**
     * @returns CostItem
     * @throws ApiError
     */
    public static apiPurchaseRequestsCostItemsList(): CancelablePromise<Array<CostItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/costItems/',
        });
    }
    /**
     * @param requestBody
     * @returns CostItem
     * @throws ApiError
     */
    public static apiPurchaseRequestsCostItemsCreate(
        requestBody: CostItem,
    ): CancelablePromise<CostItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/costItems/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Статья расходов.
     * @returns CostItem
     * @throws ApiError
     */
    public static apiPurchaseRequestsCostItemsRetrieve(
        id: number,
    ): CancelablePromise<CostItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/costItems/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Статья расходов.
     * @param requestBody
     * @returns CostItem
     * @throws ApiError
     */
    public static apiPurchaseRequestsCostItemsUpdate(
        id: number,
        requestBody: CostItem,
    ): CancelablePromise<CostItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-requests/costItems/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Статья расходов.
     * @param requestBody
     * @returns CostItem
     * @throws ApiError
     */
    public static apiPurchaseRequestsCostItemsPartialUpdate(
        id: number,
        requestBody?: PatchedCostItem,
    ): CancelablePromise<CostItem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-requests/costItems/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Статья расходов.
     * @returns void
     * @throws ApiError
     */
    public static apiPurchaseRequestsCostItemsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-requests/costItems/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static apiPurchaseRequestsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/generate_all_reports/',
        });
    }
    /**
     * @param requestId
     * @returns binary
     * @throws ApiError
     */
    public static apiPurchaseRequestsGenerateReportRetrieve(
        requestId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/generate_report/',
            query: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param request
     * @returns PurchaseRequestHistoryAction
     * @throws ApiError
     */
    public static apiPurchaseRequestsHistoryList(
        request?: number,
    ): CancelablePromise<Array<PurchaseRequestHistoryAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/history/',
            query: {
                'request': request,
            },
        });
    }
    /**
     * @returns MeasureUnit
     * @throws ApiError
     */
    public static apiPurchaseRequestsMeasureUnitsList(): CancelablePromise<Array<MeasureUnit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/measureUnits/',
        });
    }
    /**
     * @param requestBody
     * @returns MeasureUnit
     * @throws ApiError
     */
    public static apiPurchaseRequestsMeasureUnitsCreate(
        requestBody: MeasureUnit,
    ): CancelablePromise<MeasureUnit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/measureUnits/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Единица измерения.
     * @returns MeasureUnit
     * @throws ApiError
     */
    public static apiPurchaseRequestsMeasureUnitsRetrieve(
        id: number,
    ): CancelablePromise<MeasureUnit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/measureUnits/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Единица измерения.
     * @param requestBody
     * @returns MeasureUnit
     * @throws ApiError
     */
    public static apiPurchaseRequestsMeasureUnitsUpdate(
        id: number,
        requestBody: MeasureUnit,
    ): CancelablePromise<MeasureUnit> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-requests/measureUnits/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Единица измерения.
     * @param requestBody
     * @returns MeasureUnit
     * @throws ApiError
     */
    public static apiPurchaseRequestsMeasureUnitsPartialUpdate(
        id: number,
        requestBody?: PatchedMeasureUnit,
    ): CancelablePromise<MeasureUnit> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-requests/measureUnits/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Единица измерения.
     * @returns void
     * @throws ApiError
     */
    public static apiPurchaseRequestsMeasureUnitsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-requests/measureUnits/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseRequestsPositionFileUploadCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/position_file_upload/',
            errors: {
                400: `No response body`,
            },
        });
    }
    /**
     * @param search A search term.
     * @returns Product
     * @throws ApiError
     */
    public static apiPurchaseRequestsProductsList(
        search?: string,
    ): CancelablePromise<Array<Product>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/products/',
            query: {
                'search': search,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Product
     * @throws ApiError
     */
    public static apiPurchaseRequestsProductsCreate(
        requestBody: Product,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/products/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Продукт.
     * @returns Product
     * @throws ApiError
     */
    public static apiPurchaseRequestsProductsRetrieve(
        id: number,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/products/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Продукт.
     * @param requestBody
     * @returns Product
     * @throws ApiError
     */
    public static apiPurchaseRequestsProductsUpdate(
        id: number,
        requestBody: Product,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-requests/products/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Продукт.
     * @param requestBody
     * @returns Product
     * @throws ApiError
     */
    public static apiPurchaseRequestsProductsPartialUpdate(
        id: number,
        requestBody?: PatchedProduct,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-requests/products/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Продукт.
     * @returns void
     * @throws ApiError
     */
    public static apiPurchaseRequestsProductsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-requests/products/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns PurchaseRequestProject
     * @throws ApiError
     */
    public static apiPurchaseRequestsProjectsList(): CancelablePromise<Array<PurchaseRequestProject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/projects/',
        });
    }
    /**
     * @param requestBody
     * @returns PurchaseRequestProject
     * @throws ApiError
     */
    public static apiPurchaseRequestsProjectsCreate(
        requestBody: PurchaseRequestProject,
    ): CancelablePromise<PurchaseRequestProject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/projects/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Проект.
     * @returns PurchaseRequestProject
     * @throws ApiError
     */
    public static apiPurchaseRequestsProjectsRetrieve(
        id: number,
    ): CancelablePromise<PurchaseRequestProject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Проект.
     * @param requestBody
     * @returns PurchaseRequestProject
     * @throws ApiError
     */
    public static apiPurchaseRequestsProjectsUpdate(
        id: number,
        requestBody: PurchaseRequestProject,
    ): CancelablePromise<PurchaseRequestProject> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-requests/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Проект.
     * @param requestBody
     * @returns PurchaseRequestProject
     * @throws ApiError
     */
    public static apiPurchaseRequestsProjectsPartialUpdate(
        id: number,
        requestBody?: PatchedPurchaseRequestProject,
    ): CancelablePromise<PurchaseRequestProject> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-requests/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Проект.
     * @returns void
     * @throws ApiError
     */
    public static apiPurchaseRequestsProjectsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-requests/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseRequestsPublishRequestCreate(
        requestId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/publish_request/',
            query: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param comment
     * @param personId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseRequestsReworkRequestCreate(
        comment: string,
        personId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/rework_request/',
            query: {
                'comment': comment,
                'person_id': personId,
            },
        });
    }
    /**
     * @param number
     * @returns PurchaseRequestList
     * @throws ApiError
     */
    public static apiPurchaseRequestsSearchByNumberList(
        number: string,
    ): CancelablePromise<Array<PurchaseRequestList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/search-by-number/',
            query: {
                'number': number,
            },
        });
    }
    /**
     * @param counterparty
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `OnApproval` - On Approval
     * * `Approved` - Approved
     * @returns PaginatedPurchaseOrderListList
     * @throws ApiError
     */
    public static apiPurchaseOrdersList(
        counterparty?: number,
        number?: string,
        page?: number,
        pageSize?: number,
        workflowStep?: 'Approved' | 'Draft' | 'OnApproval' | 'OnCoordination',
    ): CancelablePromise<PaginatedPurchaseOrderListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase_orders/',
            query: {
                'counterparty': counterparty,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param formData
     * @returns PurchaseOrderCreateResponse
     * @throws ApiError
     */
    public static apiPurchaseOrdersCreate(
        formData: PurchaseOrderCreate,
    ): CancelablePromise<PurchaseOrderCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase_orders/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @returns PurchaseOrderRetrieve
     * @throws ApiError
     */
    public static apiPurchaseOrdersRetrieve(
        id: number,
    ): CancelablePromise<PurchaseOrderRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase_orders/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @param formData
     * @returns PurchaseOrderCreate
     * @throws ApiError
     */
    public static apiPurchaseOrdersUpdate(
        id: number,
        formData: PurchaseOrderCreate,
    ): CancelablePromise<PurchaseOrderCreate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase_orders/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseOrdersPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase_orders/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @returns void
     * @throws ApiError
     */
    public static apiPurchaseOrdersDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase_orders/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Retrieve a list of purchase orders.
     * @returns PurchaseOrderList
     * @throws ApiError
     */
    public static apiPurchaseOrdersAllList(): CancelablePromise<Array<PurchaseOrderList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase_orders/all/',
        });
    }
    /**
     * @param orderId
     * @param personId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseOrdersCoordinateOrderCreate(
        orderId: string,
        personId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase_orders/coordinate_order/',
            query: {
                'order_id': orderId,
                'person_id': personId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static apiPurchaseOrdersGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase_orders/generate_all_reports/',
        });
    }
    /**
     * @param orderId
     * @param counterparty
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `OnApproval` - On Approval
     * * `Approved` - Approved
     * @returns PaginatedPurchaseOrderHistoryActionList
     * @throws ApiError
     */
    public static apiPurchaseOrdersGetHistoryActionsList(
        orderId: string,
        counterparty?: number,
        number?: string,
        page?: number,
        pageSize?: number,
        workflowStep?: 'Approved' | 'Draft' | 'OnApproval' | 'OnCoordination',
    ): CancelablePromise<PaginatedPurchaseOrderHistoryActionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase_orders/get_history_actions/',
            query: {
                'counterparty': counterparty,
                'number': number,
                'order_id': orderId,
                'page': page,
                'page_size': pageSize,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param orderId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseOrdersPublishOrderCreate(
        orderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase_orders/publish_order/',
            query: {
                'order_id': orderId,
            },
        });
    }
    /**
     * @param orderId
     * @returns any No response body
     * @throws ApiError
     */
    public static apiPurchaseOrdersReworkOrderCreate(
        orderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase_orders/rework_order/',
            query: {
                'order_id': orderId,
            },
        });
    }
    /**
     * @param createdAt
     * @param createdBy
     * @param date
     * @param fromUser
     * @param number
     * @param order
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param toUser
     * @returns PaginatedReceivingListList
     * @throws ApiError
     */
    public static apiReceivingsList(
        createdAt?: string,
        createdBy?: string,
        date?: string,
        fromUser?: string,
        number?: string,
        order?: number,
        page?: number,
        pageSize?: number,
        toUser?: string,
    ): CancelablePromise<PaginatedReceivingListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/',
            query: {
                'created_at': createdAt,
                'created_by': createdBy,
                'date': date,
                'from_user': fromUser,
                'number': number,
                'order': order,
                'page': page,
                'page_size': pageSize,
                'to_user': toUser,
            },
        });
    }
    /**
     * @param formData
     * @returns ReceivingCreate
     * @throws ApiError
     */
    public static apiReceivingsCreate(
        formData: ReceivingCreate,
    ): CancelablePromise<ReceivingCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/receivings/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @returns ReceivingRetrieve
     * @throws ApiError
     */
    public static apiReceivingsRetrieve(
        id: number,
    ): CancelablePromise<ReceivingRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @param formData
     * @returns ReceivingCreate
     * @throws ApiError
     */
    public static apiReceivingsUpdate(
        id: number,
        formData: ReceivingCreate,
    ): CancelablePromise<ReceivingCreate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @returns any No response body
     * @throws ApiError
     */
    public static apiReceivingsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @returns void
     * @throws ApiError
     */
    public static apiReceivingsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static apiReceivingsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/receivings/generate_all_reports/',
        });
    }
    /**
     * @param id
     * @returns binary
     * @throws ApiError
     */
    public static apiReceivingsGenerateReportRetrieve(
        id: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/generate_report/',
            query: {
                'id': id,
            },
        });
    }
    /**
     * @param requestId
     * @param createdAt
     * @param createdBy
     * @param date
     * @param fromUser
     * @param number
     * @param order
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param toUser
     * @returns PaginatedReceivingHistoryActionList
     * @throws ApiError
     */
    public static apiReceivingsGetHistoryActionsList(
        requestId: string,
        createdAt?: string,
        createdBy?: string,
        date?: string,
        fromUser?: string,
        number?: string,
        order?: number,
        page?: number,
        pageSize?: number,
        toUser?: string,
    ): CancelablePromise<PaginatedReceivingHistoryActionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/get_history_actions/',
            query: {
                'created_at': createdAt,
                'created_by': createdBy,
                'date': date,
                'from_user': fromUser,
                'number': number,
                'order': order,
                'page': page,
                'page_size': pageSize,
                'request_id': requestId,
                'to_user': toUser,
            },
        });
    }
    /**
     * @returns Warehouse
     * @throws ApiError
     */
    public static apiReceivingsWarehouseList(): CancelablePromise<Array<Warehouse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/warehouse/',
        });
    }
    /**
     * @param requestBody
     * @returns Warehouse
     * @throws ApiError
     */
    public static apiReceivingsWarehouseCreate(
        requestBody: Warehouse,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/receivings/warehouse/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @returns Warehouse
     * @throws ApiError
     */
    public static apiReceivingsWarehouseRetrieve(
        id: number,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/warehouse/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @param requestBody
     * @returns Warehouse
     * @throws ApiError
     */
    public static apiReceivingsWarehouseUpdate(
        id: number,
        requestBody: Warehouse,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/receivings/warehouse/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @param requestBody
     * @returns Warehouse
     * @throws ApiError
     */
    public static apiReceivingsWarehousePartialUpdate(
        id: number,
        requestBody?: PatchedWarehouse,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/receivings/warehouse/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @returns void
     * @throws ApiError
     */
    public static apiReceivingsWarehouseDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/receivings/warehouse/{id}/',
            path: {
                'id': id,
            },
        });
    }
}
