/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Draft` - Draft
 * * `OnCoordination` - On Coordination
 * * `Coordinated` - Coordinated
 * * `PartialPaid` - PartialPaid
 * * `Paid` - Paid
 */
export enum PaymentWorkflowStepEnum {
    DRAFT = 'Draft',
    ON_COORDINATION = 'OnCoordination',
    COORDINATED = 'Coordinated',
    PARTIAL_PAID = 'PartialPaid',
    PAID = 'Paid',
}
