import React from 'react';
import {Button, Card, Result} from "antd";
import {useTranslation} from "react-i18next";

export const ForbiddenPage = () => {
    const {t} = useTranslation();

    return (
        <Card>
            <Result
                status="403"
                title="403"
                subTitle={t("Sorry, you are not authorized to access this page.")}
                extra={<Button type="primary" href={'/'}>{t('Back Home')}</Button>}
            />
        </Card>
    );
};