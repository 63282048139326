import axios from "axios";
import {DOMAIN} from "../../constants";

class AccountsApi {

    async login(email:string, password:string) {
        const { data } = await axios.post(`${DOMAIN}/api/accounts/login/`, { email, password });
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
    }

    logout() {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }

};


const accountsApi = new AccountsApi(); 

export default accountsApi;