import React from "react";
import {AppRoutes} from "./AppRoutes";
import {RouterProvider} from "react-router-dom";
import {UserProvider} from "./context";

function App() {
    return (
        <UserProvider>
            <RouterProvider router={AppRoutes}/>
        </UserProvider>
    );
}

export default App;
