/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `KZT` - KZT
 * * `USD` - USD
 * * `RUB` - RUB
 * * `EURO` - EURO
 * * `RMB` - RMB
 */
export enum CurrencyEnum {
    KZT = 'KZT',
    USD = 'USD',
    RUB = 'RUB',
    EURO = 'EURO',
    RMB = 'RMB',
}
