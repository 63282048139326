import React from "react";
import {Spin} from "antd";

const SimpleLoader = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
            <Spin size="large"/>
        </div>
    );
};

export default SimpleLoader;