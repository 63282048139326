/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Draft` - Draft
 * * `OnCoordination` - On Coordination
 * * `OnApproval` - On Approval
 * * `Approved` - Approved
 */
export enum PurchaseOrderWorkflowStepEnum {
    DRAFT = 'Draft',
    ON_COORDINATION = 'OnCoordination',
    ON_APPROVAL = 'OnApproval',
    APPROVED = 'Approved',
}
