import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Resources } from "./Resources";

i18n.use(initReactI18next).init({
  lng: "ru",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: Resources,
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
  },
});

export default i18n;
