import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {User} from "../api";

export interface UserContextProps {
    user: User | null;
    isAuthenticated: boolean;
    refreshUser: () => void;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);

    const updateUserFromLocalStorage = useCallback(() => {
        const storedUser = localStorage.getItem('userInfo');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        updateUserFromLocalStorage();
    }, [updateUserFromLocalStorage, forceUpdate]);

    const refreshUser = useCallback(() => {
        setForceUpdate(prev => !prev);
        updateUserFromLocalStorage();
    }, [updateUserFromLocalStorage]);


    return (
        <UserContext.Provider value={{user, isAuthenticated, refreshUser }}>
            {children}
        </UserContext.Provider>
    );
};
